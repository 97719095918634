<template>
    <div>
        <el-popover popper-class="audio-popper" ref="audiopopper" placement="bottom-start" v-model="opnePopover" title="" width="350" trigger="manual">
            <div class="popper_main">
                <div class="popper_input">
                    <el-input class="search" v-model="keyword" size="small" @input="searchInput" prefix-icon="el-icon-search" placeholder="输入关键词搜索"></el-input>
                </div>
                <div class="audio_content">
                    <div class="audio_content_menu" v-show="!searchShow">
                        <div class="menu_li" :class="{active:labelName==item.name}" v-for="item,index in labels" :key="index" @click="changeLabel(item.name)">{{item.name}}</div>
                    </div>
                    <div class="audio_content_list" :class="{search:searchShow}" v-loading="loading">
                        <div class="kong" v-if="this.audioList.length==0">
                            暂无数据~
                        </div>
                        <div class="list_li" v-for="item in audioList" :key="item.id">
                            <div v-if="!item.children || item.children.length==0">
                                <div class="card_item" @click.stop="changeAudio(item)" :class="{active:activeItem==item.id}">
                                    <div class="img">
                                        <img :src="item.avatar" alt="">
                                    </div>
                                    <div class="info">
                                        <div class="info_t">
                                            <div class="name">{{item.name}}</div>
                                            <div class="play">
                                                <img v-if="audioId==item.id" src="@/assets/images/workben/stop.png" @click.stop="stop(item)" alt="">
                                                <img v-else src="@/assets/images/workben/play.png" @click.stop="play(item)" alt="">
                                            </div>
                                        </div>
                                        <div class="info_b">
                                            <div class="label">中文</div>
                                            <div class="type">{{item.label}}</div>
                                        </div>
                                    </div>
                                    <audio v-if="audioId==item.id" :src="item.audio_url" @ended="overAudio" autoplay="autoplay" hidden></audio>
                                </div>
                            </div>
                            <div v-else>
                                <div class="card_item" @click.stop="item.show=!item.show">
                                    <div class="img">
                                        <img :src="item.avatar" alt="">
                                    </div>
                                    <div class="info">
                                        <div class="info_t">
                                            <div class="name">{{item.name}}</div>
                                            <div class="play">
                                                <img :class="{rotate:!item.show}" src="@/assets/images/workben/expand.png" alt="">
                                            </div>
                                        </div>
                                        <div class="info_b">
                                            <div class="label">中文</div>
                                            <div class="type">{{item.label}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.show">
                                    <div v-for="ele in item.children" :key="ele.id" @click.stop="changeAudio(ele)" :class="{active:activeItem==ele.id}" class="card_item childer">
                                        <div class="img">
                                            <img :src="ele.avatar" alt="">
                                        </div>
                                        <div class="info">
                                            <div class="info_t">
                                                <div class="name">{{ele.name}}</div>
                                                <div class="play">
                                                    <img v-if="audioId==ele.id" src="@/assets/images/workben/stop.png" @click.stop="stop(ele)" alt="">
                                                    <img v-else src="@/assets/images/workben/play.png" @click.stop="play(ele)" alt="">
                                                </div>
                                            </div>
                                            <div class="info_b">
                                                <div class="label">英文</div>
                                                <div class="type">{{ele.label}}</div>
                                            </div>
                                        </div>
                                        <audio v-if="audioId==ele.id" :src="ele.audio_url" @ended="overAudio" autoplay="autoplay" hidden></audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popper_bottom">
                <div class="li">
                    <div class="text">语速</div>
                    <div class="input"><el-input class="num" size="small" type="number" v-model="default_audio.speed_rate" placeholder="" @change="postEmit"></el-input></div>
                    <div class="slider">
                        <el-slider v-model="default_audio.speed_rate" :min="-20" :max="20" :step="1" :show-tooltip="false" @change="postEmit"></el-slider>
                    </div>
                </div>
                <div class="li">
                    <div class="text">音量</div>
                    <div class="input"><el-input class="num" size="small" type="number" v-model="default_audio.volume" placeholder="" @change="postEmit"></el-input></div>
                    <div class="slider">
                        <el-slider v-model="default_audio.volume" :min="-20" :max="20" :step="1" :show-tooltip="false" @change="postEmit"></el-slider>
                    </div>
                </div>
                <div class="li">
                    <div class="text">语调</div>
                    <div class="input"><el-input class="num" size="small" type="number" v-model="default_audio.pitch" placeholder="" @change="postEmit"></el-input></div>
                    <div class="slider">
                        <el-slider v-model="default_audio.pitch" :min="-20" :max="20" :step="1" :show-tooltip="false" @change="postEmit"></el-slider>
                    </div>
                </div>
                <div class="li">
                    <div class="text">采样率</div>
                    <div class="input" style="width: 110px;">
                        <el-select size="small" v-model="default_audio.sample_rate" placeholder="请选择" @change="postEmit">
                            <el-option v-for="item in sample_rate" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="audio_box" slot="reference">
                <div class="img">
                    <img :src="default_audio.avatar" alt="">
                </div>
                <div class="info">
                    <div class="info_t">
                        {{default_audio.name}}
                    </div>
                    <div class="info_b">
                        {{default_audio.label}}
                    </div>
                </div>
                <div class="play" @click="playCurrentAudio">
                    <img v-if="audioId=='current'" src="@/assets/images/workben/stop.png" alt="">
                    <img v-else src="@/assets/images/workben/play.png" alt="">
                </div>
                <i class="el-icon-d-arrow-right" :class="{rotate:opnePopover}" @click="open"></i>
                <audio v-if="audioId=='current'" :src="default_audio.audio_url" @ended="overAudio" autoplay="autoplay" hidden></audio>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    props: {
        default_tts: {
            type: Object,
            default: {}
        },
        sample_rate: {
            type: Array,
            default: []
        },
        labels: {  //分类列表
            type: Array,
            default: []
        },
        tts_list: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            loading: false,
            opnePopover: false,  //弹窗
            searchShow: false,  //是否输入框搜索
            keyword: '',
            labelName: '',   //分类名称
            audioList: this.tts_list, //声音列表
            activeItem: 128,   //选中声音
            timer: null,
            audioId: '',   //播放声音的ID
            default_audio: this.default_tts,  //默认选中声音
        };
    },
    created() {
        this.getList()
    },
    mounted() {
        document.addEventListener('click', (e) => {
            if (e.target.className == 'el-dialog__wrapper' || e.target.className == 'from' || e.target.className == 'richText-inner') {
                this.opnePopover = false;
            }
        })
    },
    watch: {
        opnePopover(val) {
            if (!val && this.audioId != 'current') {
                this.audioId = ''
            }
        },
    },
    methods: {
        // 打开弹窗
        open() {
            this.opnePopover = !this.opnePopover
        },
        postEmit() {
            this.$emit('postEmit', this.default_audio)
        },
        // 选中声音
        changeAudio(val) {
            this.default_audio = val
            this.activeItem = val.id
            this.opnePopover = false
            this.$emit('postEmit', this.default_audio)
        },
        // 语音结束
        overAudio() {
            this.audioId = ''
        },
        // 播放选中语言
        playCurrentAudio() {
            if (this.audioId == 'current') {
                this.audioId = ''
            } else {
                this.audioId = 'current'
            }
        },
        // 播放声音
        play(val) {
            this.audioId = val.id
        },
        // 停止声音
        stop() {
            this.audioId = ''
        },
        // 获取列表
        getList() {
            let obj = {
                keyword: this.keyword,
                label: this.keyword ? '' : this.labelName,
            }
            this.loading = true
            this.$digitalApi.getTtsList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    if (res.result) {
                        let arr = res.result
                        arr.forEach(ele => {
                            ele.show = false
                        })
                        this.audioList = arr
                    } else {
                        this.audioList = []
                    }
                }
            })
        },
        // 选中分类
        changeLabel(val) {
            this.labelName = val
            this.getList()
        },
        // 搜索框节流
        searchInput(e) {
            let that = this
            if (e) {
                this.searchShow = true
            } else {
                this.searchShow = false
            }
            clearTimeout(that.timer)
            that.timer = setTimeout(() => {
                that.getList()
            }, 500)
        },
    },
};
</script>

<style scoped lang="scss">
.popper_main {
    .popper_input {
        width: 320px;
    }
    .kong {
        text-align: center;
        color: #999999;
        line-height: 300px;
        font-size: 14px;
    }
    .audio_content {
        width: 320px;
        height: 324px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        border-top: none;
        display: flex;
        .audio_content_menu {
            width: 77px;
            height: 100%;
            background: #ffffff;
            overflow-y: auto;
            .menu_li {
                width: 71px;
                height: 40px;
                border-left: 3px solid #ffffff;
                font-size: 12px;
                color: #333333;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
            .menu_li:hover {
                color: #2e4bf2;
            }
            .menu_li.active {
                background: rgba(45, 74, 241, 0.1);
                border-left: 3px solid #2e4bf2;
                color: #2e4bf2;
            }
        }

        .audio_content_list {
            width: 254px;
            height: 100%;
            padding-bottom: 10px;
            overflow-y: auto;
            .list_li {
                .card_item.childer {
                    background: #e8e8e8;
                    border: 1px solid #e8e8e8;
                }
                .card_item {
                    cursor: pointer;
                    width: 225px;
                    height: 68px;
                    background: #ffffff;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    padding: 0 11px;
                    margin-top: 10px;
                    margin-left: 10px;
                    border: 1px solid #ffffff;
                    .img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .info {
                        margin-left: 12px;
                        .info_t {
                            display: flex;
                            align-items: center;
                            .name {
                                font-size: 14px;
                                color: #333333;
                                width: 130px;
                            }
                            .play {
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .rotate {
                                transform: rotate(-90deg);
                                -webkit-transform: rotate(-90deg);
                                transition-duration: 0.3s;
                            }
                        }
                        .info_b {
                            display: flex;
                            align-items: center;
                            padding-top: 6px;
                            .label {
                                width: 34px;
                                height: 18px;
                                background: #ff9237;
                                border-radius: 2px;
                                font-size: 12px;
                                color: #ffffff;
                                line-height: 18px;
                                text-align: center;
                            }
                            .type {
                                width: 109px;
                                font-size: 12px;
                                color: #999999;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                                margin-left: 6px;
                            }
                        }
                    }
                }
                .card_item.active {
                    border: 1px solid #2d4af1;
                }
            }
        }
        .audio_content_list.search {
            width: 318px;
            .list_li {
                width: 100%;
                .card_item {
                    width: 300px;
                    padding: 0 25px;
                    .info {
                        .info_t {
                            .name {
                                width: 160px;
                            }
                        }
                        .info_b {
                            .type {
                                width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.popper_bottom {
    width: 320px;
    height: 196px;
    background: #ffffff;
    border-radius: 2px;
    margin-top: 10px;
    padding: 10px;
    .li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .text {
            width: 55px;
            color: #666666;
            padding-left: 2px;
        }
        .input {
            width: 55px;
            margin-right: 14px;
        }
        .slider {
            width: 170px;
        }
    }
}
.audio_box {
    width: 270px;
    height: 56px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    margin-top: 14px;
    padding: 0 15px;
    display: flex;
    align-items: center;

    .img {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .info {
        margin-left: 8px;
        width: 130px;
        .info_t {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
        }
        .info_b {
            padding-top: 2px;
            width: 130px;
            font-size: 12px;
            color: #999999;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
        }
    }
    .play {
        width: 20px;
        height: 20px;
        margin-left: 14px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .el-icon-d-arrow-right {
        font-size: 14px;
        color: #666666;
        margin-left: 14px;
        cursor: pointer;
    }
    .rotate {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transition-duration: 0.3s;
    }
}

::v-deep .search .el-input__inner {
    background: #ededed;
    border-radius: 4px;
}
::v-deep .num .el-input__inner {
    padding: 0 5px;
    text-align: center;
}
.audio_content_menu::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_menu::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    // -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_menu::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px #ffffff;
    border-radius: 5px;
    background: #ffffff;
}
.audio_content_list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #f4f4f6;
    border-radius: 5px;
    background: #f4f4f6;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
</style>
<style lang="scss">
.audio-popper {
    background: #f8f8f8;
    box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.34);
    border: none;
    padding: 12px 15px;

    .popper__arrow {
        display: none;
    }
}
</style>
