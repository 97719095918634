<template>
    <div class="model-market">
        <div class="model-search">
            <div class="model-search-l">
                <div class="model-search-c">
                    <div class="search-type" :class="{active:type==item.value}" v-for="item in typeList" :key="item.value" @click="changeType(item.value)">
                        {{item.label}}
                    </div>
                </div>
                <div class="model-search-c" style="margin-top: 20px;">
                    <div class="search-class" :class="{active:theme_type==item.id}" v-for="item in classify" :key="item.id" @click="changeClassify(item.id)">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="model-search-r">
                <div class="select-box">
                    <el-select size="small" v-model="aspect_ratio" placeholder="全部" @change="search">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-box">
                    <el-input size="small" v-model="name" @keyup.enter.native="search" placeholder="搜索模板关键字"></el-input>
                </div>
            </div>
        </div>
        <list ref="list" v-loading="loading" :aspect_ratio="aspect_ratio" element-loading-background="rgba(195,216,247,0.8)" :list="list" @getList="getList"></list>
        <div class="page_box">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import List from './list.vue';  //列表
export default {
    components: {
        EmptyData,
        PagingPage,
        List,
    },
    props: {

    },
    data() {
        return {
            loading: false,
            page: 1,
            total: 0,
            limit: 10,
            options: [],
            aspect_ratio: 2,  //1横版 2竖版
            name: '',
            typeList: [],
            classify: [],
            type: "",
            theme_type: "",
            list: []
        };
    },
    created() {
        this.getParams()
    },
    methods: {
        changeType(val) {
            this.type = val
            this.theme_type = ''
            this.getThemeType()
        },
        changeClassify(val) {
            this.theme_type = val
            this.getList()
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                type: this.type,
                theme_type: this.theme_type,
                name: this.name,
                aspect_ratio: this.aspect_ratio,
            }
            this.loading = true;
            this.$digitalApi.templateList(obj).then(res => {
                this.loading = false;
                if (res.code == 1000) {
                    this.total = res.result.total
                    this.list = res.result.data
                    this.$refs.list.current = ''
                }
            })
        },
        // 获取筛选参数
        getParams() {
            this.$digitalApi.templateParams().then(res => {
                if (res.code == 1000) {
                    this.options = res.result.aspect_ratio;
                    this.typeList = res.result.theme_category;
                    this.type = res.result.theme_category[0].value;
                    this.getThemeType()
                }
            })
        },
        // 获取主题分类
        getThemeType() {
            let obj = {
                type: this.type,
            }
            this.loading = true;
            this.$digitalApi.themeType(obj).then(res => {
                if (res.code == 1000) {
                    this.classify = res.result
                    this.classify.unshift({
                        id: '',
                        name: '全部'
                    })
                    this.getList()
                }
            })
        },
        search() {
            this.page = 1
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList()
        },
    },
};
</script>

<style scoped lang="scss">
.model-search {
    width: 100%;
    background: rgba($color: #ffffff, $alpha: 0.4);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    .model-search-l {
        width: calc(100% - 230px);
        .model-search-c {
            display: flex;
            flex-wrap: wrap;

            .search-type {
                color: $bColor3;
                font-size: 14px;
                margin-right: 20px;
                cursor: pointer;
                padding: 0 12px;
                line-height: 30px;
                border-radius: 15px;
            }
            .search-type.active {
                color: #ffffff;
                background: $blueColor1;
            }
            .search-class {
                font-size: 14px;
                margin-right: 20px;
                cursor: pointer;
                color: $blueColor1;
                width: 80px;
                line-height: 26px;
                height: 28px;
                background: rgba(46, 75, 242, 0.06);
                border-radius: 2px;
                border: 1px solid rgba(46, 75, 242, 0.06);
                text-align: center;
            }
            .search-class.active {
                border: 1px solid $blueColor1;
            }
        }
    }
    .model-search-r {
        display: flex;
        justify-content: flex-end;
        width: 230px;
        .tit {
            font-size: 12px;
            color: #333333;
            line-height: 17px;
            padding-right: 10px;
        }
        .select-box {
            width: 102px;
            margin-right: 20px;
        }
        .search-box {
            width: 178px;
        }
    }
}
.page_box {
    margin-top: 20px;
}
</style>
