<template>
    <div class="upload_img" :style="[allstyle]">
        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadimg">
            <img v-if="url" :src="url" class="avatar">
            <div v-else class="avatar-uploader-icon" :class="{gray:color=='gray'}">
                <i class="el-icon-plus"></i>
                <p>上传图片</p>
            </div>
        </el-upload>
    </div>
</template>
<script>

export default {
    props: {
        width: {
            type: String,
            default: '90px'
        },
        height: {
            type: String,
            default: '90px'
        },
        url: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            is_img: ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'],
        }
    },
    computed: {
        allstyle() {
            return {
                '--uploadW': this.width,
                '--uploadH': this.height,
            }
        },
    },
    watch: {

    },
    methods: {
        // 上传图片
        uploadimg(item) {
            var self = this
            // 限制图片格式
            const isJPG = this.is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                let newFile = new FormData()
                // newFile.append('type', 4); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
                newFile.append('file', item.file);
                self.$creationApi.uploadDraw(newFile).then(res => {
                    if (res.code == 1000) {
                        this.$emit('uploadSuccess', res.result.short_url, res.result.url, this.id)
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img {
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #2e4bf2;
    }

    .avatar-uploader-icon {
        width: var(--uploadW);
        height: var(--uploadH);
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        text-align: center;
        color: #2e4bf2;

        i {
            font-size: 32px;
            font-weight: 500;
            margin-top: 16px;
        }

        p {
            font-size: 12px;
            line-height: 17px;
            margin-top: 6px;
        }
    }
    .avatar-uploader-icon.gray {
        color: #999999;
        i {
            font-size: 16px;
            margin-top: 30px;
        }
    }

    .avatar {
        width: var(--uploadW);
        height: var(--uploadH);
        display: block;
    }
}
</style>