<template>
    <div class="upload_img" :style="[allstyle]">
        <el-upload class="avatar-uploader" action="#" :accept="imgAccept" :show-file-list="false" :http-request="uploadimg">
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
                <div class="tips-box" slot="content">
                    <div class="tips-top">
                        <img class="icon" src="@/assets/images/digital/warn.png" alt="">
                        <div class="text">注意事项</div>
                    </div>
                    <div class="tips-main">
                        <img class="tips-img" src="@/assets/images/digital/avatar.png" alt="">
                        <div class="line1"></div>
                        <div class="line2"></div>
                        <div class="text1">留有间距</div>
                        <div class="text2">不露牙齿</div>
                    </div>
                    <div class="tips-bottom">
                        请勿上传敏感、违规图片
                    </div>
                </div>
                <img v-if="url" :src="url" class="avatar">
                <div v-else class="avatar-uploader-icon">
                    <i class="el-icon-plus"></i>
                    <p>上传头像</p>
                </div>
            </el-tooltip>
        </el-upload>
    </div>
</template>
<script>

export default {
    props: {
        width: {
            type: String,
            default: '100px'
        },
        height: {
            type: String,
            default: '100px'
        },
        url: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            is_img: ['image/png', 'image/jpg', 'image/jpeg'],
            imgAccept: 'image/jpg,image/jpeg,image/png',
        }
    },
    computed: {
        allstyle() {
            return {
                '--uploadW': this.width,
                '--uploadH': this.height,
            }
        },
    },
    watch: {

    },
    methods: {
        // 上传图片
        uploadimg(item) {
            var self = this
            // 限制图片格式
            const isJPG = this.is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                let newFile = new FormData()
                // newFile.append('type', 4); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
                newFile.append('file', item.file);
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                self.$creationApi.uploadAvatar(newFile).then(res => {
                    if (res.code == 1000) {
                        this.$emit('uploadSuccess', res.result.url)
                        loading.close();
                    } else {
                        self.$errMsg(res.message)
                        loading.close();
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img {
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #666666;
    }

    .avatar-uploader-icon {
        width: var(--uploadW);
        height: var(--uploadH);
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        text-align: center;
        color: #999999;

        i {
            font-size: 16px;
            font-weight: 500;
            margin-top: 30px;
        }

        p {
            font-size: 12px;
            line-height: 17px;
            margin-top: 6px;
        }
    }

    .avatar {
        width: var(--uploadW);
        height: var(--uploadH);
        display: block;
    }
}

.tips-box {
    width: 155px;
    height: 115px;
    opacity: 0.8;
    .tips-top {
        display: flex;
        align-items: center;
        .icon {
            width: 12px;
            height: 12px;
        }
        .text {
            font-size: 12px;
            color: #ffffff;
            padding-left: 6px;
        }
    }
    .tips-main {
        width: 100%;
        height: 75px;
        margin-top: 8px;
        position: relative;
        font-size: 12px;
        color: #ffffff;
        .tips-img {
            width: 75px;
            height: 75px;
        }
        .line1 {
            position: absolute;
            width: 30px;
            height: 1px;
            border-bottom: 1px dashed #ffffff;
            left: 68px;
            top: 13px;
        }
        .line2 {
            position: absolute;
            width: 58px;
            height: 1px;
            border-bottom: 1px dashed #ffffff;
            left: 40px;
            top: 50px;
        }
        .text1 {
            position: absolute;
            top: 5px;
            left: 100px;
        }
        .text2 {
            position: absolute;
            top: 42px;
            left: 100px;
        }
    }
    .tips-bottom {
        font-size: 12px;
        color: #ffffff;
        margin-top: 6px;
    }
}
</style>