<template>
    <div class="model-market">
        <div class="model-search">
            <div class="model-search-l">
                <div class="search-nav" :class="{ active: current == item.value }" v-for="item in category" :key="item.value" @click="changeNav(item.value)">
                    {{ item.label }}
                </div>
            </div>
            <div class="model-search-r">
                <div class="tit">模特背景：</div>
                <div class="select-box">
                    <el-select size="small" v-model="background_type" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in backgroundTypeOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="tit">性别：</div>
                <div class="select-box">
                    <el-select size="small" v-model="gender" placeholder="全部" clearable @change="search">
                        <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-box">
                    <el-input size="small" v-model="name" @keyup.enter.native="search" placeholder="搜索形象关键字" suffix-icon="el-icon-search"></el-input>
                </div>
            </div>
        </div>
        <div class="list" v-loading="loading" element-loading-background="rgba(195,216,247,0.9)">
            <empty-data v-if="list.length == 0" img='no_img_b.png' text='暂无数据'></empty-data>
            <div class="list-item" v-for="item, index in list" :key="index">
                <div class="list-img" @click="detailsBtn(item)">
                    <div class="tips" v-if="item.is_free == '1'">免费</div>
                    <div class="tips vip" v-else-if="item.is_vip == '1'">
                        <img src="@/assets/images/digital/vip.png" alt=""> VIP
                    </div>
                    <img class="img" :src="item.thumbnail" alt="">
                </div>
                <div class="item-bottom">
                    <div class="item-bottom-l">
                        <div class="item-name" @click="detailsBtn(item)">{{ item.name }}</div>
                        <div class="item-hot"><img src="@/assets/images/digital/hot.png" alt=""> {{ item.hits }}人气</div>
                    </div>
                    <!-- 1 如果是免费商品 直接显示去制作 is_free=1是免费 -->
                    <!-- 2 如果是需要购买会员 判断用户是否为会员 is_vip=1需要购买会员 2不需要 -->
                    <!-- 3 如果是既不是免费商品 也不需要购买会员 判断是否买过 has_buy=1购买过 0没买过 -->
                    <div class="item-bottom-r">
                        <div class="open-make" v-if="item.is_free == '1' || (item.is_vip == '1' && userInfo.is_vip) || (item.is_free == '2' && item.is_vip == '2' && item.has_buy == 1)" @click="createBtn(item)">
                            去制作<i class="el-icon-arrow-right"></i>
                        </div>
                        <div class="open-vip" v-else-if="item.is_vip == '1' && !userInfo.is_vip" @click="memberBtn">
                            开通会员
                        </div>
                        <div class="diamond" v-else-if="item.has_buy == 0">
                            {{ item.price }}钻 <img src="@/assets/images/digital/diamond.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paging-page :total='total' :pageSizes="[12,24,36,48]" align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        <open-member ref='dialogMember'></open-member>

    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import OpenMember from '@/components/invest_money/open_member.vue' // 弹框==开通会员

import { mapState } from "vuex"

export default {
    components: { EmptyData, PagingPage, OpenMember, },
    data() {
        return {
            loading: false,
            page: 1,
            limit: 12,
            total: 0,
            backgroundTypeOptions: [],
            background_type: '',
            genderOptions: [],
            gender: '',
            name: '',
            category: [],
            current: "is_recom",
            list: [],
        };
    },
    created() {
        this.getModelParams()
        this.getList()
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
        }),
    },
    methods: {
        getModelParams() {
            this.$digitalApi.modelParams().then(res => {
                if (res.code == 1000) {
                    this.category = res.result.category
                    this.category.unshift(
                        {
                            value: "is_recom",
                            label: "推荐",
                        },
                        {
                            value: "is_free",
                            label: "免费",
                        },
                    )
                    this.genderOptions = res.result.gender
                    this.backgroundTypeOptions = res.result.background_type
                }
            })
        },
        changeNav(val) {
            if (this.current == val) {
                return
            } else {
                this.page = 1;
                this.limit = 12;
                this.background_type = '';
                this.gender = '';
                this.name = '';
                this.total = 0;
                this.current = val
                this.getList()
            }
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                is_recom: this.current == 'is_recom' ? '1' : '',
                is_free: this.current == 'is_free' ? '1' : '',
                category: this.current == 'is_recom' ? '' : this.current == 'is_free' ? '' : this.current,
                background_type: this.background_type,
                gender: this.gender,
                name: this.name,
            }
            this.loading = true
            this.$digitalApi.modelList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.total = res.result.total
                    this.list = res.result.data
                }
            })
        },
        search() {
            this.page = 1
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList()
        },
        // 跳转详情
        detailsBtn(val) {
            this.$router.push({
                path: '/digital/model_details/' + val.id,
                query: {
                    name: val.name
                },
            })
        },
        // 购买会员
        memberBtn() {
            this.$refs.dialogMember.openDialogBtn()
        },
        // 去制作
        createBtn() {
            this.$textMsg('努力开发中')
        }
    },
};
</script>

<style scoped lang="scss">
.model-search {
    width: 100%;
    height: 62px;
    background: rgba($color: #ffffff, $alpha: 0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .model-search-l {
        display: flex;
        align-items: center;

        .search-nav {
            width: 80px;
            height: 28px;
            background: $wColor;
            border-radius: 2px;
            color: $bColor3;
            line-height: 28px;
            text-align: center;
            font-size: 14px;
            margin-right: 20px;
            cursor: pointer;
        }

        .search-nav.active {
            background: $blueColor1;
            color: $wColor;
        }
    }

    .model-search-r {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .tit {
            font-size: 12px;
            color: #333333;
            line-height: 17px;
            padding-right: 10px;
        }

        .select-box {
            width: 102px;
            margin-right: 20px;
        }

        .search-box {
            width: 178px;
        }
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    min-height: 450px;
    margin-bottom: 20px;

    .list-item {
        margin-right: 20px;
        margin-top: 20px;
        width: 235px;
        height: 422px;
        border-radius: 12px;
        overflow: hidden;

        .list-img {
            width: 235px;
            height: 350px;
            background: #cecfdb;
            position: relative;

            .img {
                width: auto;
                height: 100%;
                margin: 0 auto;
            }

            .tips {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 10px;
                height: 21px;
                background: linear-gradient(90deg, #ffd279 0%, #ffc04d 100%);
                border-radius: 0px 12px 0px 7px;
                color: #774501;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tips.vip {
                background: linear-gradient(90deg, #79a6ff 0%, #614dff 100%);
                color: #ffffff;
                line-height: 21px;
                font-size: 12px;

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 2px;
                }
            }
        }

        .item-bottom {
            width: 235px;
            height: 72px;
            background: rgba(255, 255, 255, 0.9);
            padding: 11px 10px;
            display: flex;
            justify-content: space-between;

            .item-bottom-l {
                .item-name {
                    font-size: 16px;
                    font-weight: 500;
                    color: $bColor3;
                    line-height: 22px;
                }

                .item-hot {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $bColor9;
                    line-height: 20px;
                    padding-top: 7px;

                    img {
                        width: 15px;
                        height: 15px;
                        margin-right: 2px;
                    }
                }
            }

            .item-bottom-r {
                .open-vip {
                    width: 68px;
                    height: 25px;
                    background: linear-gradient(
                        180deg,
                        #ffde9f 0%,
                        #ffd382 100%
                    );
                    border-radius: 2px;
                    color: #774501;
                    line-height: 25px;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                }

                .open-make {
                    width: 68px;
                    height: 25px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 2px;
                    color: #ffffff;
                    line-height: 25px;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                }

                .diamond {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: $blueColor1;
                    line-height: 33px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 23px;
                        height: 22px;
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}
</style>
