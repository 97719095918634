<template>
    <div>
        <div class="content">
            <div class="content-l" v-if="aspect_ratio==2">
                <div class="content-item">
                    <div class="item-t">
                        <i class="el-icon-plus"></i>
                    </div>
                    <div class="item-b">新建空白</div>
                </div>
            </div>
            <div class="content-l horizontal" v-else>
                <div class="content-item">
                    <div class="item-t">
                        <i class="el-icon-plus"></i>
                    </div>
                    <div class="item-b">新建空白</div>
                </div>
            </div>
            <div class="list" v-if="aspect_ratio==2">
                <div class="list-item" v-for="item,index in list" :key="index">
                    <div class="list-img">
                        <div class="tips" v-if="item.is_free=='1'">限时免费</div>
                        <div class="tips" v-else>付费</div>
                        <div class="video" v-if="item.id==current">
                            <video controls="controls" autoplay="autoplay" :src="item.video_url" @click="current=''"></video>
                        </div>
                        <div class="cover" v-show="item.id!==current">
                            <img class="img" :src="item.video_img" alt="">
                            <img class="play" src="@/assets/images/digital/play.png" @click="changeVideo(item)" alt="">
                        </div>
                    </div>
                    <div class="item-bottom">
                        <div class="item-bottom-t">
                            <div class="item-name-two" v-if="item.is_free=='1'">
                                {{item.name}}
                            </div>
                            <div class="item-name" v-else>
                                <span class="name"> {{item.name}} </span>
                                <span class="price">{{item.price}} <img src="@/assets/images/digital/diamond.png" alt=""> / 次</span>
                            </div>
                            <div class="item-price">
                                <el-button class="btn" v-if="item.is_free=='1'||item.has_buy=='1'" size="mini" type="primary">使用</el-button>
                                <el-button class="btn" v-else size="mini" :loading="btnLoading==item.id" type="primary" @click="getOrder(item)">购买</el-button>
                            </div>
                        </div>
                        <div class="item-info">
                            <div class="item-hot"><img src="@/assets/images/digital/hot.png" alt="">{{item.hits}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list horizontal" v-if="aspect_ratio==1">
                <div class="list-item" v-for="item,index in list" :key="index">
                    <div class="list-img">
                        <div class="tips" v-if="item.is_free=='1'">限时免费</div>
                        <div class="tips" v-else>付费</div>
                        <div class="video" v-if="item.id==current">
                            <video controls="controls" autoplay="autoplay" :src="item.video_url" @click="current=''"></video>
                        </div>
                        <div class="cover" v-show="item.id!==current">
                            <img class="img" :src="item.video_img" alt="">
                            <img class="play" src="@/assets/images/digital/play.png" @click="changeVideo(item)" alt="">
                        </div>
                    </div>
                    <div class="item-bottom">
                        <div class="item-bottom-t">
                            <div class="item-name-two" v-if="item.is_free=='1'">
                                {{item.name}}
                            </div>
                            <div class="item-name" v-else>
                                <span class="name"> {{item.name}} </span>
                            </div>
                            <div class="item-price">
                                <span class="price">{{item.price}} <img src="@/assets/images/digital/diamond.png" alt=""> / 次</span>
                                <el-button class="btn" v-if="item.is_free=='1'||item.has_buy=='1'" size="mini" type="primary">使用</el-button>
                                <el-button class="btn" v-else size="mini" :loading="btnLoading==item.id" type="primary" @click="getOrder(item)">购买</el-button>
                            </div>
                        </div>
                        <div class="item-info">
                            <div class="item-hot"><img src="@/assets/images/digital/hot.png" alt="">{{item.hits}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 支付 -->
        <pay-diamond ref='dialogdiamond' @renovate='paySuccess'></pay-diamond>
    </div>
</template>

<script>
import { mapState } from "vuex"
import PayDiamond from '@/components/pay_page/pay_diamond.vue'  //支付
export default {
    components: { PayDiamond },
    props: {
        list: Array,
        aspect_ratio: Number,
    },
    data() {
        return {
            current: '',
            btnLoading: '',
        };
    },
    created() {

    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
        }),
    },
    methods: {
        getOrder(item) {
            let obj = {
                id: item.id
            }
            this.btnLoading = item.id
            this.$digitalApi.templateOrder(obj).then(res => {
                this.btnLoading = ''
                if (res.code == 1000) {
                    let parmas = {
                        diamond: this.userInfo.diamond,
                        price: item.price,
                        order_num: res.result.order_num,
                        id: item.id,
                        name: item.name,
                    }
                    this.$refs.dialogdiamond.openDialogBtn(parmas, 6)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 支付成功
        paySuccess() {
            this.$emit('getList')
        },
        // 切换播放视频
        changeVideo(val) {
            this.current = val.id
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    display: flex;
    justify-content: space-between;
    .content-l {
        width: 228px;
        .content-item {
            width: 237px;
            height: 482px;
            border-radius: 10px 10px 2px 2px;
            overflow: hidden;
            margin-top: 20px;
            .item-t {
                width: 237px;
                height: 422px;
                background: #ffffff;
                line-height: 422px;
                text-align: center;
                color: $blueColor1;
                font-size: 30px;
                font-weight: 600;
                background: url(~@/assets/images/digital/upload_bg.png)
                    no-repeat 100%/100%;
            }
            .item-b {
                width: 237px;
                height: 60px;
                background: $blueColor1;
                line-height: 60px;
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
    .content-l.horizontal {
        width: 427px;
        .content-item {
            width: 427px;
            height: 305px;

            .item-t {
                width: 427px;
                height: 241px;
                line-height: 241px;
            }
            .item-b {
                width: 427px;
                height: 64px;
                background: $blueColor1;
                line-height: 64px;
                font-size: 18px;
            }
        }
    }
}
.list.horizontal {
    width: calc(100% - 447px);
    .list-item {
        width: 427px;
        height: 305px;
        .list-img {
            width: 427px;
            height: 241px;
        }
        .item-bottom {
            width: 427px;
            height: 64px;
            padding: 9px 11px;
            .item-bottom-t {
                .item-name {
                    .name {
                        max-width: 150px;
                    }
                }
                .item-price {
                    display: flex;
                    align-items: center;
                    .price {
                        color: $blueColor1;
                        font-size: 14px;
                        padding-right: 10px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0 3px;
                        }
                    }
                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 58px;
                        height: 22px;
                        background: $blueColor1;
                        border-radius: 2px;
                        color: #ffffff;
                        text-align: center;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
                .item-name-two {
                    width: 180px;
                }
            }
            .item-info {
                padding-top: 8px;
            }
        }
    }
}
.list {
    width: calc(100% - 257px);
    display: flex;
    flex-wrap: wrap;

    .list-item {
        margin-right: 20px;
        margin-top: 20px;
        width: 237px;
        height: 482px;
        border-radius: 10px 10px 2px 2px;
        overflow: hidden;
        .list-img {
            width: 237px;
            height: 422px;
            position: relative;
            .cover {
                width: 100%;
                height: 100%;
                position: relative;
                .img {
                    width: 100%;
                    height: 100%;
                }
                .play {
                    width: 46px;
                    height: 46px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
            .video {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                video {
                    width: auto;
                    height: 100%;
                    margin: 0 auto;
                }
            }
            .tips {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 10px;
                height: 22px;
                background: linear-gradient(90deg, #ffd279 0%, #ffc04d 100%);
                border-radius: 0px 10px 0px 7px;
                color: #774501;
                font-size: 14px;
                line-height: 22px;
                z-index: 11;
            }
        }

        .item-bottom {
            width: 237px;
            height: 60px;
            background: rgba(255, 255, 255, 0.9);
            padding: 6px 10px;
            .item-bottom-t {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item-name {
                    display: flex;
                    align-items: center;
                    .name {
                        font-size: 16px;
                        color: $bColor3;
                        line-height: 22px;
                        max-width: 75px;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .price {
                        color: $blueColor1;
                        font-size: 14px;
                        padding-right: 10px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0 3px;
                        }
                    }
                }
                .item-name-two {
                    width: 135px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .item-price {
                    display: flex;
                    align-items: center;

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 58px;
                        height: 22px;
                        background: $blueColor1;
                        border-radius: 2px;
                        color: #ffffff;
                        text-align: center;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
            }

            .item-info {
                display: flex;
                align-items: center;
                line-height: 20px;
                padding-top: 6px;
                .item-hot {
                    font-size: 14px;
                    color: #ff5f5f;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}
</style>
